import React from "react"
import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"
import RequestForm from "../RequestForm"

const CardInfo = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 2;
  margin: 0 0 30px;

  ${BreakpointUp.sm` 
        margin:0 0 60px;
    `}
`
const CardInfoBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #0b619b;
  box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;
  ${BreakpointUp.sm` 
        padding:60px; 

    `}
  @media (min-width: 1366px) {
    padding: 60px 120px;
  }
  @media (max-width: 480px) {
    padding: 30px 15px;
  }
`
const CardInfoTitle = styled.div`
  color: #fff;
  font-weight: 800;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 10px;
  @media (min-width: 992px) {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 42px;
  }
`
const CardInfoContent = styled.div`
    width:100%;
    .grid{
        display:flex;
        flex-wrap:wrap;
        margin:0 -15px;
        .grid-12{
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            ${BreakpointUp.sm`   
                flex: 0 0 50%;
                max-width: 50%;
            `}
            ${BreakpointUp.xl`                
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            `}
        }
    }
    .form-group-bordered{
        margin:20px 0;
    }
    .form-group-textarea{
        textarea.form-control{
            height:44px;
        }
    }
   

    .form-control {
        color: #fff !important;
        border-bottom: 1px solid rgba(255,255,255,0.5) !important;
        &::placeholder {
            color: rgba(255,255,255,0.5);
        }
        &:focus, &:active{
            border-bottom-color: #fff;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }        
        &:focus + .control-label{
            color:rgba(255,255,255,0.5);
        }      
        &:valid + .control-label {        
            color:rgba(255,255,255,0.5);
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active
    {
    -webkit-box-shadow: 0 0 0 30px #0B619B inset !important;
    -webkit-text-fill-color: #fff;
    }
    select.form-control option { color: #000; }    
    .control-label{
        color:rgba(255,255,255,0.5);
        font-weight: 500;
        &:focus, &:active{
            color:rgba(255,255,255,0.5);
        }
    }
    .css-1wa3eu0-placeholder {
        color: rgba(255,255,255,0.5); !important;
    }
    .css-1uccc91-singleValue, .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
        color:#fff !important;
    }
    .css-yk16xz-control, .css-1pahdxg-control{
        border-bottom: 1px solid rgba(255,255,255,0.5) !important;
        &:focus, &:active{
            border-bottom-color: #fff !important;
        }
    }
    button {
        &:hover {
            border-color: #fff;
        }
    }
`
const RequestQuote = ({ location }) => {
  return (
    <CardInfo>
      <CardInfoBody>
        <div className="container">
          <CardInfoTitle>
            Get Started Today With Your Free Quote...
          </CardInfoTitle>
          <CardInfoContent>
            <RequestForm location={location} />
          </CardInfoContent>
        </div>
      </CardInfoBody>
    </CardInfo>
  )
}

export default RequestQuote
