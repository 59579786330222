import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import { CustomValidationMessage } from "../Section"
import FormButton from "../Button/FormButton"
import StateSelect from "../StateSelect"

class RequestForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: "",
      page: "",
      form: "",
      name: "",
      email: "",
      wrongEmailFormat: false,
      phone_no: "",
      state: null,
      message: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
    if (id === "email" && value.indexOf(".com") === -1) {
      this.setState({
        wrongEmailFormat: true,
      })
    } else if (id === "message") {
      if (!value || value.trim() === "") {
        event.target.setCustomValidity("Please fill out this field.")
      } else {
        event.target.setCustomValidity("")
      }
    } else {
      this.setState({
        wrongEmailFormat: false,
      })
    }
  }
  handleChangeSelect(state) {
    this.setState({ state })
  }
  handleSubmit(event) {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    event.preventDefault()
    const { location } = this.props
    if (!this.state.wrongEmailFormat) {
      const data = {
        source: location.origin,
        page: location.href,
        form: event.target.id,
        first_name: this.state.name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state.value,
        commnet: "Message: " + this.state.message,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val,
        api_token: process.env.GATSBY_FORM_CRM_TOKEN,
      }
      if (typeof window !== `undefined`) {
        document.getElementById("requestForm").setAttribute("disabled", "")
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN,
        },
      })
        .then(res => {
          if (typeof dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "form_submission",
              formName: "RequestForm",
              enhanced_conversion_data: {
                email: this.state.email,
                phone_number: this.state.phone_no,
              },
            })
          }
          this.setState({
            source: "",
            page: "",
            form: "",
            name: "",
            email: "",
            phone_no: "",
            state: "",
            message: "",
          })
          navigate("/thank-you")
        })
        .catch(error => {
          alert(error)
        })
    } else {
      return true
    }
  }
  render() {
    return (
      <form
        id="requestform"
        onSubmit={this.handleSubmit}
        className="requestform"
      >
        <div className="grid">
          <div className="grid-12">
            <div className="form-group-bordered">
              <input
                type="text"
                id="name"
                className="form-control"
                placeholder="Name"
                onChange={this.handleChange}
                pattern="^(?!\s*$)[A-Za-z\s]+$"
                aria-label="name"
                required
              />
            </div>
          </div>
          <div className="grid-12">
            <div className="form-group-bordered">
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Email"
                onChange={this.handleChange}
                aria-label="email"
                required
              />
              {this.state.wrongEmailFormat && (
                <CustomValidationMessage width="fit-content" zIndex="10">
                  Please input correct email format: xx@xx.com
                </CustomValidationMessage>
              )}
            </div>
          </div>

          <div className="grid-12">
            <div className="form-group-bordered">
              <input
                type="tel"
                id="phone_no"
                className="form-control"
                placeholder="Phone Number"
                onChange={this.handleChange}
                pattern="[0-9]{10}"
                aria-label="phone number"
                required
              />
            </div>
          </div>

          <div className="grid-12">
            <div className="form-group-bordered form-group-select">
              <StateSelect
                id="state"
                value={this.state.state}
                onChange={this.handleChangeSelect}
              />
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="grid-md-6">
            <div className="form-group-bordered form-group-textarea">
              <textarea
                type="text"
                id="message"
                className="form-control"
                placeholder="Comments"
                onChange={this.handleChange}
                aria-label="message"
                required
                title=" "
              />
            </div>
          </div>
          <div className="grid-md-6">
            <div className="form-action">
              <FormButton
                icon={<RightLineArrowIcon />}
                text="Submit"
                id="requestForm"
              />
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default RequestForm
